import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import './plugins/element.js'
import ElementUI from 'element-ui';
// 导入全局样式表
import './assets/css/global.css'
import TreeTable from 'vue-table-with-tree-grid'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import QRCode from "qrcode"; //定义生成二维码组件
QRCode;
 
Vue.use(ViewUI);

Vue.use(ElementUI);
// 导入字体图标
import './assets/fonts/iconfont.css'
Vue.config.productionTip = false
import axios from 'axios'
// import { config } from 'vue/types/umd'
// 配置请求的根路径
const baseURL='https://api.haoshuzhi.cn/api'
const baseURL2='https://go.shuzhishidai.cn'
const service=axios.create({
	baseURL:baseURL,
	headers:{
	  Authorization:localStorage.getItem('Authorization'),
	  'content-type':'multipart/form-data; boundary=----WebKitFormBoundaryrSlAACn2AIFaiZPW'
	}
})
const service1=axios.create({
	baseURL:baseURL2,
	headers:{
	  // Authorization:localStorage.getItem('Authorization'),
	  'Content-Type': 'multipart/form-data'
	}
})
// service.defaults.baseURL =baseURL
service.interceptors.request.use((config) => {
    config.headers={
      Authorization:localStorage.getItem('Authorization'),
      'content-type':'multipart/form-data; boundary=----WebKitFormBoundaryrSlAACn2AIFaiZPW'
    }
    // console.log(config);
    return config
})
service.interceptors.response.use(
    response => {
        if (response.status === 200) {            
            return Promise.resolve(response);        
        } else {            
            return Promise.reject(response);        
        }    
    }, 
	error =>{
		if(error.response.status){
			console.log(error.response.status)
			switch (error.response.status){
				case 401:
					localStorage.removeItem('Authorization');                 
                    router.push('/login')                
				    break;
			}
		}
	}
	
)
service1.interceptors.request.use((config) => {
    config.headers={
      Authorization:localStorage.getItem('Authorization'),
      'content-type':'multipart/form-data; boundary=----WebKitFormBoundaryrSlAACn2AIFaiZPW'
    }
    // console.log(config);
    return config
})
service1.interceptors.response.use(
    response => {   
        if (response.status === 200) {            
            return Promise.resolve(response);        
        } else {            
            return Promise.reject(response);        
        }    
    }, 
	error =>{
		if(error.response.status){
			console.log(error.response.status)
			switch (error.response.status){
				case 401:
					localStorage.removeItem('Authorization');                 
                    router.push('/login')                
				    break;
			}
		}
	}
	
)
Vue.prototype.$http = service

Vue.prototype.$http1 = service1

Vue.component('tree-table', TreeTable)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'activePath') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}