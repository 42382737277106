import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login'
},
{
  path: '/login',
  component: () =>
    import('../views/login.vue')
},{
  path: '/Home',
  component: () =>
    import('../views/Home.vue'),
  redirect: '/codeSet',
  children: [{
    path: '/codeSet',
    component: () =>
      import('../views/codeSet.vue')
  }, {
    path: '/inquireList',
    component: () =>
      import('../views/inquire/inquireList.vue')
  },{
    path: '/setting',
    component: () =>
      import('../views/set/setting.vue')
  }]
},

];

const router = new VueRouter({
  routes
});
// 挂载导航守卫
// router.beforeEach((to, from, next) => {
//     const token_ = window.sessionStorage.getItem('token')
//     if (to.path !== '/login' && !token_) {
//         next('/login');
//     }
//     next();


// })



export default router